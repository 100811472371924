import React from "react";
import GatsbyLink from "../src/components/GatsbyLink";
import "./Logo.scss";

export const Logo = () => {
  return (
    <GatsbyLink to="/" className="logo">
      <svg width="226" height="72" viewBox="0 0 226 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1_24)">
      <path d="M69.66 41.62H69.6C68.67 43.2 67.02 44.17 64.93 44.17C61.25 44.17 57.77 41.78 57.77 36.11V28.02H61.61V36.11C61.61 39.14 63.22 40.59 65.64 40.59C68.06 40.59 69.67 39.07 69.67 36.11V28.02H73.51V43.4C73.51 48.94 70 51.17 65.58 51.17C61.87 51.17 58.81 49.46 57.91 45.69H61.75C62.39 47.17 63.65 47.85 65.59 47.85C68.2 47.85 69.68 46.59 69.68 43.5V41.63L69.66 41.62Z" fill="black"/>
      <path d="M93.61 43.94H90.1V40.97H90.04C89.17 43 87.11 44.35 84.4 44.35C80.6 44.35 76.53 41.61 76.53 35.97C76.53 31.13 80.08 27.59 85.11 27.59C90.14 27.59 93.62 30.97 93.62 36.1V43.93L93.61 43.94ZM89.77 35.98C89.77 33.18 87.9 31.18 85.1 31.18C82.3 31.18 80.39 33.18 80.39 35.98C80.39 38.78 82.29 40.78 85.1 40.78C87.91 40.78 89.77 38.78 89.77 35.98Z" fill="black"/>
      <path d="M106.92 34.85V34.69C106.92 32.11 105.63 31.18 103.76 31.18C101.89 31.18 100.6 32.11 100.6 34.69V43.94H96.76V34.69C96.76 29.89 99.6 27.6 103.76 27.6C107.92 27.6 110.72 29.89 110.72 34.76V34.86H106.92V34.85Z" fill="black"/>
      <path d="M129.23 43.94H125.72V40.97H125.66C124.79 43 122.73 44.35 120.02 44.35C116.22 44.35 112.15 41.61 112.15 35.97C112.15 31.13 115.7 27.59 120.73 27.59C125.76 27.59 129.24 30.97 129.24 36.1V43.93L129.23 43.94ZM125.39 35.98C125.39 33.18 123.52 31.18 120.72 31.18C117.92 31.18 116.01 33.18 116.01 35.98C116.01 38.78 117.91 40.78 120.72 40.78C123.53 40.78 125.39 38.78 125.39 35.98Z" fill="black"/>
      <path d="M148.83 43.94H145.32V40.97H145.26C144.39 43 142.33 44.35 139.62 44.35C135.82 44.35 131.75 41.61 131.75 35.97C131.75 31.13 135.3 27.59 140.33 27.59C145.36 27.59 148.84 30.97 148.84 36.1V43.93L148.83 43.94ZM144.99 35.98C144.99 33.18 143.12 31.18 140.32 31.18C137.52 31.18 135.61 33.18 135.61 35.98C135.61 38.78 137.51 40.78 140.32 40.78C143.13 40.78 144.99 38.78 144.99 35.98Z" fill="black"/>
      <path d="M163.88 43.94V35.65C163.88 32.62 162.27 31.17 159.85 31.17C157.43 31.17 155.82 32.62 155.82 35.65V43.94H151.98V35.65C151.98 30.33 155.14 27.59 159.85 27.59C164.56 27.59 167.72 30.3 167.72 35.65V43.94H163.88Z" fill="black"/>
      <path d="M187.32 35.85C187.32 41.01 183.84 44.36 178.81 44.36C173.78 44.36 170.23 40.81 170.23 35.98C170.23 30.34 174.29 27.6 178.1 27.6C180.49 27.6 182.39 28.63 183.42 30.24H183.48V21.05H187.32V35.85ZM183.48 35.98C183.48 33.18 181.61 31.18 178.81 31.18C176.01 31.18 174.1 33.18 174.1 35.98C174.1 38.78 176 40.78 178.81 40.78C181.62 40.78 183.48 38.78 183.48 35.98Z" fill="black"/>
      <path d="M198.41 44.36C193.38 44.36 189.83 40.81 189.83 35.98C189.83 31.15 193.38 27.6 198.41 27.6C203.44 27.6 206.99 31.15 206.99 35.98C206.99 40.81 203.44 44.36 198.41 44.36ZM203.08 35.98C203.08 33.18 201.21 31.18 198.41 31.18C195.61 31.18 193.7 33.18 193.7 35.98C193.7 38.78 195.6 40.78 198.41 40.78C201.22 40.78 203.08 38.78 203.08 35.98Z" fill="black"/>
      <path d="M217.42 44.36C212.39 44.36 208.84 40.81 208.84 35.98C208.84 31.15 212.39 27.6 217.42 27.6C222.45 27.6 226 31.15 226 35.98C226 40.81 222.45 44.36 217.42 44.36ZM222.09 35.98C222.09 33.18 220.22 31.18 217.42 31.18C214.62 31.18 212.71 33.18 212.71 35.98C212.71 38.78 214.61 40.78 217.42 40.78C220.23 40.78 222.09 38.78 222.09 35.98Z" fill="black"/>
      <path d="M14.6 25.2C13.86 24.03 14.2 22.48 15.37 21.74C16.53 21 18.09 21.34 18.83 22.51C19.57 23.68 19.23 25.23 18.06 25.97C17.64 26.24 17.18 26.37 16.72 26.37C15.89 26.37 15.08 25.96 14.6 25.21V25.2ZM25.36 24.16C23.98 24.05 22.95 22.85 23.05 21.47C23.16 20.09 24.37 19.06 25.75 19.16C27.13 19.27 28.16 20.48 28.05 21.86C27.95 23.17 26.85 24.17 25.55 24.17C25.48 24.17 25.42 24.17 25.35 24.17L25.36 24.16Z" fill="#495475"/>
      <path d="M21.87 44.43C20.55 44.03 19.8 42.63 20.2 41.3C20.6 39.98 22 39.22 23.32 39.62C24.64 40.02 25.4 41.43 25 42.75C24.67 43.83 23.68 44.53 22.6 44.53C22.36 44.53 22.11 44.49 21.87 44.42V44.43ZM17.66 36.58C16.34 36.18 15.58 34.78 15.98 33.46C16.38 32.13 17.78 31.38 19.1 31.78C20.42 32.18 21.18 33.58 20.78 34.9C20.45 35.99 19.46 36.69 18.38 36.69C18.14 36.69 17.89 36.65 17.65 36.58H17.66ZM25.51 32.36C24.18 31.96 23.43 30.56 23.82 29.24C24.22 27.91 25.62 27.16 26.94 27.55C28.27 27.95 29.02 29.35 28.62 30.68C28.29 31.77 27.3 32.47 26.22 32.47C25.98 32.47 25.74 32.44 25.5 32.36H25.51Z" fill="#31B7BC"/>
      <path d="M31.94 57.32C31.33 56.08 31.85 54.58 33.1 53.97C34.35 53.36 35.85 53.88 36.45 55.13C37.06 56.37 36.54 57.87 35.29 58.48C34.93 58.65 34.56 58.73 34.2 58.73C33.27 58.73 32.37 58.21 31.94 57.32ZM40.43 51.94C39.35 51.07 39.17 49.5 40.03 48.41C40.89 47.33 42.47 47.15 43.55 48.01C44.63 48.87 44.81 50.45 43.95 51.53C43.46 52.15 42.73 52.48 41.99 52.48C41.44 52.48 40.89 52.3 40.43 51.93V51.94ZM45.77 43.42C44.42 43.11 43.57 41.76 43.88 40.42C44.19 39.07 45.53 38.22 46.88 38.53C48.23 38.84 49.08 40.18 48.77 41.53C48.5 42.69 47.47 43.48 46.33 43.48C46.14 43.48 45.96 43.46 45.77 43.42ZM43.87 31.54C43.56 30.19 44.41 28.85 45.76 28.54C47.11 28.23 48.46 29.08 48.76 30.43C49.07 31.78 48.22 33.13 46.87 33.43C46.68 33.48 46.49 33.5 46.31 33.5C45.17 33.5 44.13 32.71 43.87 31.55V31.54ZM40.01 23.55C39.14 22.47 39.32 20.89 40.4 20.03C41.48 19.16 43.06 19.34 43.93 20.42C44.79 21.5 44.62 23.08 43.54 23.95C43.08 24.32 42.52 24.5 41.98 24.5C41.24 24.5 40.52 24.18 40.02 23.56L40.01 23.55ZM33.07 18.01C31.82 17.41 31.3 15.91 31.9 14.66C32.5 13.41 34 12.89 35.25 13.49C36.49 14.09 37.02 15.59 36.41 16.84C35.98 17.73 35.09 18.25 34.15 18.25C33.78 18.25 33.41 18.17 33.06 18L33.07 18.01Z" fill="#31B7BC"/>
      <path d="M13.59 58.5C12.34 57.9 11.82 56.4 12.42 55.15C13.02 53.91 14.52 53.38 15.77 53.99C17.01 54.59 17.54 56.09 16.93 57.34C16.5 58.23 15.61 58.75 14.67 58.75C14.3 58.75 13.93 58.67 13.58 58.5H13.59ZM4.91 51.57C4.05 50.49 4.22 48.91 5.31 48.04C6.39 47.18 7.97 47.35 8.83 48.44C9.7 49.52 9.52 51.1 8.44 51.96C7.98 52.33 7.43 52.51 6.88 52.51C6.15 52.51 5.42 52.19 4.92 51.57H4.91ZM0.0699978 41.57C-0.240002 40.22 0.609998 38.87 1.96 38.57C3.31 38.26 4.66 39.1 4.96 40.46C5.27 41.81 4.42 43.15 3.07 43.46C2.88 43.5 2.69 43.52 2.51 43.52C1.37 43.52 0.329998 42.73 0.0699978 41.57ZM1.95 33.46C0.599998 33.15 -0.240002 31.81 0.0599978 30.46C0.369998 29.11 1.71 28.26 3.06 28.57C4.41 28.88 5.26 30.22 4.95 31.57C4.68 32.73 3.65 33.52 2.51 33.52C2.32 33.52 2.14 33.5 1.95 33.46ZM5.28 23.97C4.2 23.11 4.02 21.53 4.88 20.45C5.74 19.37 7.32 19.19 8.41 20.05C9.49 20.91 9.67 22.49 8.81 23.58C8.31 24.2 7.59 24.52 6.85 24.52C6.3 24.52 5.75 24.34 5.29 23.97H5.28ZM12.39 16.86C11.79 15.62 12.3 14.12 13.55 13.51C14.79 12.91 16.29 13.43 16.9 14.67C17.5 15.91 16.99 17.41 15.74 18.02C15.39 18.19 15.02 18.27 14.65 18.27C13.72 18.27 12.83 17.75 12.39 16.86Z" fill="#31B7BC"/>
      <path d="M25.46 50.57C25.12 49.23 25.94 47.87 27.29 47.54C28.63 47.2 29.99 48.02 30.33 49.37C30.67 50.71 29.85 52.07 28.5 52.41C28.29 52.46 28.09 52.49 27.89 52.49C26.76 52.49 25.74 51.73 25.46 50.58V50.57ZM16.6 50.98C15.38 50.34 14.9 48.82 15.54 47.59C16.18 46.36 17.7 45.89 18.93 46.53C20.16 47.17 20.63 48.69 19.99 49.92C19.54 50.77 18.67 51.26 17.77 51.26C17.38 51.26 16.98 51.16 16.61 50.97L16.6 50.98ZM34.85 46.21C33.71 45.42 33.43 43.86 34.21 42.72C35 41.58 36.56 41.29 37.7 42.08C38.84 42.87 39.12 44.43 38.34 45.57C37.85 46.28 37.07 46.65 36.27 46.65C35.78 46.65 35.29 46.51 34.85 46.21ZM8.64 42.04C8.14 40.75 8.79 39.3 10.08 38.8C11.37 38.3 12.82 38.95 13.32 40.24C13.82 41.53 13.17 42.98 11.88 43.48C11.58 43.59 11.28 43.65 10.98 43.65C9.97 43.65 9.02 43.04 8.64 42.04ZM36.22 34.55C36.05 33.18 37.03 31.92 38.4 31.76C39.77 31.59 41.02 32.57 41.19 33.95C41.36 35.32 40.38 36.57 39 36.74C38.9 36.74 38.79 36.76 38.69 36.76C37.44 36.76 36.36 35.83 36.2 34.56L36.22 34.55Z" fill="#495475"/>
      <path d="M24.42 17.8C26.7783 17.8 28.69 15.8883 28.69 13.53C28.69 11.1717 26.7783 9.26 24.42 9.26C22.0617 9.26 20.15 11.1717 20.15 13.53C20.15 15.8883 22.0617 17.8 24.42 17.8Z" fill="#31B7BC"/>
      <path d="M33.95 29.49C36.3083 29.49 38.22 27.5783 38.22 25.22C38.22 22.8617 36.3083 20.95 33.95 20.95C31.5917 20.95 29.68 22.8617 29.68 25.22C29.68 27.5783 31.5917 29.49 33.95 29.49Z" fill="#495475"/>
      <path d="M30.42 42.08C32.7783 42.08 34.69 40.1683 34.69 37.81C34.69 35.4517 32.7783 33.54 30.42 33.54C28.0617 33.54 26.15 35.4517 26.15 37.81C26.15 40.1683 28.0617 42.08 30.42 42.08Z" fill="#31B7BC"/>
      <path d="M24.42 62.74C26.7783 62.74 28.69 60.8283 28.69 58.47C28.69 56.1117 26.7783 54.2 24.42 54.2C22.0617 54.2 20.15 56.1117 20.15 58.47C20.15 60.8283 22.0617 62.74 24.42 62.74Z" fill="#31B7BC"/>
      <path d="M10.96 35.17C13.3183 35.17 15.23 33.2583 15.23 30.9C15.23 28.5417 13.3183 26.63 10.96 26.63C8.60175 26.63 6.69 28.5417 6.69 30.9C6.69 33.2583 8.60175 35.17 10.96 35.17Z" fill="#495475"/>
      </g>
      <defs>
      <clipPath id="clip0_1_24">
      <rect width="226" height="72" fill="white"/>
      </clipPath>
      </defs>
      </svg>
    </GatsbyLink>
  );
};
