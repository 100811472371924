import React, { useEffect, useState } from "react";
import { StaticQuery, graphql } from "gatsby";
import { LogoFooter } from "../../../theme/LogoFooter";
import GatsbyLink from "../GatsbyLink";
import { SocialLinks } from "../SocialLinks";
import "./Footer.scss";
import { decodeEntities } from "../../utils/helpers";
import { useIsClient } from "../../hooks";

const Footer = ({ data, location }) => {
  const {
    footerNavigation: { items: footerNav },
    legalNavigation: { items: legalNav },
    siteSettings: {
      options: { locations },
    },
  } = data;
  const contactDetails = locations[0];

  const [isHomePage, setHomePage] = useState();

  useEffect(() => {
    if (useIsClient) {
      location.pathname !== "/" ? setHomePage(false) : setHomePage(true);
    }
  }, [location]);

  return (
    <footer className="footer">
      <div className="wrap">
        <div className="footer-primary">
          <div className="footer-col">
            <LogoFooter />
            <div className="contact-details">
              <a
                href={`mailto:${decodeEntities(contactDetails.email)}`}
                className="contact-detail contact-detail--mail"
              >
                {decodeEntities(locations[0].email)}
              </a>
              <a
                href={`tel:${contactDetails.phone.replace(/[^A-Z0-9]/gi, "")}`}
                className="contact-detail contact-detail--phone"
                aria-label="Call Now"
              >
                {contactDetails.phone}
              </a>
            </div>
            <SocialLinks />
          </div>
          {Object.keys(footerNav).length > 0 &&
            footerNav?.map((parentItem, index) => {
              return (
                <ul className="footer-col" key={index}>
                  {parentItem.title !== "group" ? (
                    <li className={`footer-item ${parentItem.classes}`}>
                      <GatsbyLink to={parentItem.url}>
                        {decodeEntities(parentItem.title)}
                      </GatsbyLink>
                    </li>
                  ) : (
                    ""
                  )}

                  {parentItem.children &&
                    Object.keys(parentItem.children).length > 0 &&
                    parentItem.children?.map((childItem, childIndex) => {
                      return (
                        <>
                          <li
                            className={`footer-item ${childItem.classes}`}
                            key={childIndex}
                          >
                            {childItem.url === "#" ? (
                              <span>{decodeEntities(childItem.title)}</span>
                            ) : (
                              <GatsbyLink to={childItem.url}>
                                {decodeEntities(childItem.title)}
                              </GatsbyLink>
                            )}
                          </li>
                          {/* map through grandchildren */}
                          {childItem.grandchildren
                            ? Object.keys(childItem.grandchildren).length > 0 &&
                              childItem.grandchildren?.map((grand, index) => {
                                return (
                                  <li className="footer-item" key={index}>
                                    {/* if URL is PDF aboid the gatsby link because it adds a trailing slash */}
                                    {grand.url.includes(".pdf") ? (
                                      <a href={grand.url}>
                                        {decodeEntities(grand.title)}
                                      </a>
                                    ) : (
                                      <GatsbyLink to={grand.url}>
                                        {decodeEntities(grand.title)}
                                      </GatsbyLink>
                                    )}
                                  </li>
                                );
                              })
                            : ""}
                        </>
                      );
                    })}
                </ul>
              );
            })}
        </div>
        <div className="footer-legal">
          <span>
            Copyright Yaraandoo Pty Ltd {new Date().getFullYear()} -{" "}
            <span className="nowrap">
              All Rights Reserved ABN 57 648 701 946
            </span>
          </span>
          <ul className="legal-navigation">
            {Object.keys(legalNav).length > 0 &&
              legalNav?.map((navItem, index) => {
                return (
                  <li className="legal-item" key={index}>
                    <GatsbyLink to={navItem.url}>{navItem.title}</GatsbyLink>
                  </li>
                );
              })}
            <li className="legal-item">
              {isHomePage ? (
                <span className="distl">
                  Website by{" "}
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://distl.com.au/"
                  >
                    Distl
                  </a>
                </span>
              ) : (
                <span className="distl">Website by Distl</span>
              )}
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        legalNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "legal-navigation" }
        ) {
          items {
            title
            url
            classes
            slug: object_slug
          }
        }
        footerNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "footer-navigation" }
        ) {
          items {
            title
            url
            classes
            slug: object_slug
            children: wordpress_children {
              title
              url
              classes
              slug: object_slug
              grandchildren: wordpress_children {
                title
                url
                classes
                slug: object_slug
              }
            }
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            locations {
              addressDisplay
              email
              phone
            }
            footerTagline
            socialLinksFooter
            navigationInFooter
            contactDetailsInFooter
            privacyCookiePolicyNotice
            privacyCookiePolicyNoticeText
          }
        }
        wordpressWpSettings {
          title
        }
      }
    `}
    render={(data) => <Footer data={data} {...props} />}
  />
);
